<template>
  <div class="pt-4 md:px-3 pb-10">
    <div class="flex items-center text-xl font-semibold">Required Information</div>
    <div class="flex flex-col md:flex-row pb-6">
      <JobDropdownField
        v-model="jobPost.guideType"
        :options="filters['guide_type']['guide_type']"
        error="guide type"
        :allow-empty="false"
        required="required"
        placeholder="Select primary job position"
        tooltip-text="Only candidates of this type will be able to apply for this job."
        title="Primary job position"
        size="1"
        class="w-full md:pr-3 pb-4 md:pb-0"
        :disabled="editSync"
      >
      </JobDropdownField>

      <JobDropdownField
        v-model="jobPost.secondaryGuideType"
        :options="secondaryJobPositions"
        error="secondary guide type"
        :allow-empty="true"
        :placeholder="jobPost.guideType === '' ? 'Select primary job position first' : 'Select secondary job position (optional)'"
        tooltip-text="Selecting a secondary job position will also allow candidates of this type to apply for the job."
        title="Secondary job position"
        size="1"
        class="w-full"
        :disabled="jobPost.guideType === ''"
      >
      </JobDropdownField>
    </div>

    <div class="vx-col w-full lg:w-1/1 sm:w-1/1 mr-auto pb-6">
      <label for="title" class="typo__label">Job Title</label>
      <vs-input
        id="jobTitle"
        v-model="jobPost.jobTitle"
        v-validate="'required'"
        name="title"
        class="w-full mt-1"
        placeholder="Enter job title"
      />
      <span v-show="errors.has('title')" class="text-danger text-sm">{{ errors.first('title') }}</span>
    </div>
    <div class="flex flex-col md:flex-row pb-6"
      ><JobDropdownField
        v-model="jobPost.location"
        :options="filters.location['location']"
        error="location"
        required="required"
        placeholder="Select country"
        title="Country"
        size="1"
        class="w-full md:pr-3 pb-4 md:pb-0"
      >
      </JobDropdownField>
      <JobDropdownField
        v-model="jobPost.cities"
        :options="cities['Kph0kuMjWpANt5Xr7Iqk'][jobPost.location]"
        error="region"
        :allow-empty="true"
        required="required"
        :placeholder="regionPlaceholder"
        :disabled="disableRegionField"
        title="Region"
        size="1"
        class="w-full"
        :searchable="true"
      >
      </JobDropdownField>
    </div>
    <div class="flex flex-col md:flex-row"
      ><JobDropdownField
        v-model="jobPost.languages"
        :options="filters['languages']['languages']"
        error="languages"
        :multiple="true"
        :close-on-select="false"
        required="required"
        placeholder="Select languages"
        title="Languages"
        size="1"
        class="w-full md:pr-3 pb-4 md:pb-0"
        :searchable="true"
      >
      </JobDropdownField>
      <JobDropdownField
        v-model="jobPost.jobType"
        :options="returnCapacity"
        error="job type"
        :allow-empty="false"
        required="required"
        placeholder="Select job type"
        title="Job type"
        size="1"
        class="w-full"
        :disabled="editSync"
      >
      </JobDropdownField>
    </div>
    <div v-if="jobPost.jobType && jobPost.jobType !== ''" class="flex flex-col md:flex-row w-full pt-6">
      <div class="w-full">
        <label class="typo__label">Start Date</label>
        <vs-input
          v-model="startDate"
          v-validate="'required'"
          type="date"
          name="start date"
          :class="jobPost.jobType === 'Freelance' ? 'w-full' : 'w-full sm:w-1/2'"
          class="mt-1 sm:pr-3"
        />
        <span v-show="errors.has('start date')" class="text-danger text-sm">{{ errors.first('start date') }}</span>
      </div>
      <div v-if="jobPost.jobType === 'Freelance'" class="w-full">
        <label class="typo__label">End Date</label>
        <vs-input v-model="endDate" v-validate="'required'" type="date" name="end date" class="mt-1 w-full" />
        <span v-show="errors.has('end date')" class="text-danger text-sm">{{ errors.first('end date') }}</span>
      </div>
      <!-- <div class="flex flex-col md:flex-row pb-6 md:pb-0">
        <div class="w-full">
          <vs-input
            id="jobDurationNumber"
            v-model="jobDurationNumber"
            v-validate="'required|numeric'"
            placeholder="Enter an amount of days / weeks / months"
            name="job duration"
            class="mt-1 w-full pr-2 mb-2 md:mb-0"
          />
          <span v-show="errors.has('job duration')" class="text-danger text-sm">{{ errors.first('job duration') }}</span>
        </div>
        <JobDropdownField
          v-model="timePeriod"
          :options="filters['job_duration']['job_duration']"
          placeholder="Select a time period"
          error="time period"
          required="required"
          title=""
          size="1"
          class="w-full pr-2"
          :allow-empty="true"
        >
        </JobDropdownField>
      </div> -->
    </div>

    <portal name="next1" to="nextStepButton">
      <vs-button v-if="activeStep === 0" class="w-full md:w-auto" color="primary" @click="handleClick('next')">Next Step</vs-button>
    </portal>
    <portal name="save1" to="saveJob">
      <vs-button v-if="editSync" type="border" class="w-full md:w-auto" @click="handleClick('save')">{{
        !isArchived ? 'Save job' : 'Reactivate job'
      }}</vs-button>
    </portal>
  </div>
</template>

<script>
import JobDropdownField from './JobDropdownField.vue';
import moment from 'moment';

export default {
  components: {
    JobDropdownField,
  },
  props: {
    jobSync: {
      type: Object,
      required: true,
    },
    activeStep: {
      type: Number,
      required: true,
    },
    editSync: {
      type: Boolean,
    },
    isArchived: {
      type: Boolean,
    },
  },
  data() {
    return {
      jobPost: this.jobSync,
      jobDurationNumber: '',
      timePeriod: '',
      startDate: null,
      endDate: null,
    };
  },

  computed: {
    secondaryJobPositions() {
      const positions = this.filters['guide_type']['guide_type'];
      return positions.filter((position) => position !== this.jobPost.guideType);
    },
    returnCapacity() {
      const freelancePosts = this.$store.getters['employerModule/getUser'].freelanceJobPosts;
      const permanentPosts = this.$store.getters['employerModule/getUser'].permanentJobPosts;
      let capacityFilters = this.filters['capacity']['capacity'];
      if (freelancePosts === '0' || freelancePosts === 0 || !freelancePosts) {
        capacityFilters = ['Permanent'];
      }
      if (permanentPosts === '0' || permanentPosts === 0 || !permanentPosts) {
        capacityFilters = ['Freelance'];
      }
      return capacityFilters;
    },
    jobPosition() {
      return this.jobPost.guideType;
    },
    jobLocation() {
      return this.jobPost.location;
    },
    disableRegionField() {
      if (this.jobPost.location) return false;
      return true;
    },
    regionPlaceholder() {
      if (this.jobPost.location) return 'Select a region';
      return 'Select a country first';
    },
    LMMCNotRequired() {
      if (this.jobPost.guideType !== 'Lodge Manager' && this.jobPost.guideType !== 'Management Couple') {
        return 'required';
      } else {
        return false;
      }
    },
    LMMCRequired() {
      if (this.jobPost.guideType === 'Lodge Manager' || this.jobPost.guideType === 'Management Couple') {
        return 'required';
      } else {
        return false;
      }
    },
    filters() {
      return this.$store.getters['filtersModule/getFilters'];
    },
    cities() {
      return this.$store.getters['countriesModule/getCountries'];
    },
  },

  watch: {
    jobLocation() {
      this.jobPost.cities = '';
    },
    jobPosition() {
      if (this.jobPost.guideType === this.jobPost.secondaryGuideType) {
        this.jobPost.secondaryGuideType = '';
      }
    },

    // jobDurationNumber() {
    //   this.setJobDuration();
    // },
    // timePeriod() {
    //   this.setJobDuration();
    // },
  },
  created() {
    // let jobDurationSplit = this.jobPost.jobDuration.split(' ');
    // this.timePeriod = jobDurationSplit[1].charAt(0).toUpperCase() + jobDurationSplit[1].slice(1);
    // this.jobDurationNumber = jobDurationSplit[0];
    if (this.jobPost.startDate) {
      if (this.jobPost.startDate instanceof Date) {
        this.startDate = moment(this.jobPost.startDate).format('YYYY-MM-DD');
      } else {
        this.startDate = moment(this.jobPost.startDate.toDate()).format('YYYY-MM-DD');
      }
    }
    if (this.jobPost.endDate) {
      if (this.jobPost.endDate instanceof Date) {
        this.endDate = moment(this.jobPost.endDate).format('YYYY-MM-DD');
      } else {
        this.endDate = moment(this.jobPost.endDate.toDate()).format('YYYY-MM-DD');
      }
    }
  },
  methods: {
    setJobDuration() {
      this.jobPost.jobDuration = this.jobDurationNumber + ' ' + this.timePeriod.charAt(0).toLowerCase() + this.timePeriod.slice(1);
    },
    setDates() {
      this.jobPost.startDate = new Date(this.startDate);
      if (this.jobPost.jobType === 'Freelance') {
        this.jobPost.endDate = new Date(this.endDate);
      }
    },
    handleClick(inputType) {
      this.$validator.validateAll().then((result) => {
        if (result) {
          this.setDates();
          this.setJobDuration();
          switch (inputType) {
            case 'save':
              this.$emit('save-job');
              break;
            case 'next':
              this.$emit('next-step');
              break;
            default:
              break;
          }
        }
      });
    },
    changeStep(toStep) {
      this.$validator.validateAll().then((result) => {
        if (result) {
          this.setDates();
          this.setJobDuration();
          this.$emit('change-step', toStep);
        }
      });
    },
    // nextStep() {
    //   this.$validator.validateAll().then((result) => {
    //     if (result) {
    //       this.$emit('next-step');
    //     }
    //   });
    // },
    // saveJob() {
    //   this.$validator.validateAll().then((result) => {
    //     if (result) {
    //       this.$emit('save-job');
    //     }
    //   });
    // },
  },
};
</script>

<style scoped>
.vs-button:not(.vs-radius):not(.includeIconOnly):not(.small):not(.large) {
  padding-left: 2.5rem !important;
  padding-right: 2.5rem !important;
}
</style>
