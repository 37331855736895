<template>
  <div
    ><div v-if="activeStep < 3" class="-ml-px absolute mt-0.5 top-4 left-4 w-0.5 h-full bg-gray-300" aria-hidden="true"></div>
    <div href="#" class="relative flex items-start group" aria-current="step">
      <span class="h-9 flex items-center" aria-hidden="true">
        <span class="relative z-10 w-8 h-8 flex items-center justify-center border-solid bg-white border-2 border-rag-green rounded-full">
          <span class="h-2.5 w-2.5 bg-rag-green rounded-full"></span>
        </span>
      </span>
      <span class="ml-4 min-w-0 flex flex-col">
        <span class="text-lg font-semibold tracking-wide text-rag-green">{{ step.title }}</span>
        <span class="text-sm text-gray-500">{{ step.desc }}</span>
      </span>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    step: {
      type: Object,
      required: true,
    },
    activeStep: {
      type: Number,
      required: true,
    },
  },
};
</script>
