<template>
  <div>
    <div v-if="index < 3" class="-ml-px absolute mt-0.5 top-4 left-4 w-0.5 h-full bg-gray-300" aria-hidden="true"></div>

    <div :class="edit ? 'cursor-pointer' : 'cursor-default'" class="relative flex items-start group" @click="jumpStep">
      <span class="h-9 flex items-center" aria-hidden="true">
        <span
          class="relative z-10 w-8 h-8 flex items-center justify-center border-solid bg-white border-2 border-gray-300 rounded-full group-hover:border-gray-400"
        >
          <span class="h-2.5 w-2.5 bg-transparent rounded-full group-hover:bg-gray-300"></span>
        </span>
      </span>
      <span class="ml-4 min-w-0 flex flex-col">
        <span class="text-lg font-semibold tracking-wide text-gray-500">{{ step.title }}</span>
        <span class="text-sm text-gray-500">{{ step.desc }}</span>
      </span>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    step: {
      type: Object,
      required: true,
    },
    activeStep: {
      type: Number,
      required: true,
    },
    index: {
      type: Number,
      required: true,
    },
    edit: {
      type: Boolean,
      required: true,
    },
  },
  methods: {
    jumpStep() {
      if (this.edit) {
        this.$emit('jump-to-step', this.activeStep, this.index);
      }
    },
  },
};
</script>
