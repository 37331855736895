<template>
  <div class="pt-4 md:px-3 pb-10">
    <div class="flex items-center text-xl font-semibold">Job Description</div>
    <div class="py-6">
      <label class="typo__label">Job description</label>
      <editor
        v-model="jobPost.jobDescr"
        v-validate="'required'"
        class="mt-1 w-full border-red-500"
        name="description"
        :api-key="tinyApiKey"
        :init="tinyOptions"
      />
      <span v-show="errors.has('description')" class="text-danger text-sm">{{ errors.first('description') }}</span></div
    >

    <div class="pb-6">
      <label class="typo__label">Candidate requirements</label>
      <editor
        v-model="jobPost.candidateRequirements"
        v-validate="'required'"
        name="requirements"
        :api-key="tinyApiKey"
        :init="tinyOptions"
        class="mt-1 w-full"
      />
      <span v-show="errors.has('requirements')" class="text-danger text-sm">{{ errors.first('requirements') }}</span></div
    >
    <div>
      <label for="requiredSkills" class="typo__label">Required skills</label>
      <vs-input
        id="requiredSkills"
        v-model="jobPost.requiredSkills"
        v-validate="'required'"
        name="Required skills"
        class="w-full mt-1"
        placeholder="Add keywords separated by a comma"
        :danger="errors.first('Required skills') ? true : false"
      />
      <span v-show="errors.has('Required skills')" class="text-danger text-sm">{{ errors.first('Required skills') }}</span>
    </div>
    <portal name="previous2" to="previousStepButton">
      <vs-button v-if="activeStep === 1" class="w-full md:w-auto" color="grey" @click="handleClick('previous')">Previous</vs-button>
    </portal>
    <portal name="next2" to="nextStepButton">
      <vs-button v-if="activeStep === 1" class="w-full md:w-auto" color="primary" @click="handleClick('next')">Next Step</vs-button>
    </portal>
    <portal name="save2" to="saveJob">
      <vs-button v-show="editSync" type="border" class="w-full md:w-auto" @click="handleClick('save')">{{
        !isArchived ? 'Save job' : 'Reactivate job'
      }}</vs-button>
    </portal>
  </div>
</template>

<script>
import Editor from '@tinymce/tinymce-vue';
export default {
  components: {
    editor: Editor,
  },
  props: {
    jobSync: {
      type: Object,
      required: true,
    },
    activeStep: {
      type: Number,
      required: true,
    },
    editSync: {
      type: Boolean,
    },
    isArchived: {
      type: Boolean,
    },
  },
  data() {
    return {
      tinyApiKey: process.env.VUE_APP_TINYMCE_API_KEY,
      tinyOptions: {
        branding: false,
        height: 300,
        menubar: false,
        plugins: [
          'advlist autolink lists link image charmap print preview anchor',
          'searchreplace visualblocks code fullscreen',
          'insertdatetime media table paste code help wordcount',
        ],
        toolbar: 'undo redo | bold italic | \
           bullist numlist outdent indent | removeformat',
      },
      jobPost: this.jobSync,
    };
  },
  computed: {
    LMMCNotRequired() {
      if (this.jobPost.guideType !== 'Lodge Manager' && this.jobPost.guideType !== 'Management Couple') {
        return 'required';
      } else {
        return false;
      }
    },
    LMMCRequired() {
      if (this.jobPost.guideType === 'Lodge Manager' || this.jobPost.guideType === 'Management Couple') {
        return 'required';
      } else {
        return false;
      }
    },
    filters() {
      return this.$store.getters['filtersModule/getFilters'];
    },
  },

  created() {
    if (this.jobPost.requiredSkills && this.jobPost.requiredSkills.constructor === Array) {
      return (this.jobPost.requiredSkills = this.jobPost.requiredSkills.join(', '));
    } else return this.jobPost.requiredSkills;
  },

  methods: {
    handleClick(inputType) {
      if (inputType === 'previous' && !this.editSync) {
        this.$emit('previous-step');
      } else {
        this.$validator.validateAll().then((result) => {
          if (result) {
            switch (inputType) {
              case 'save':
                this.$emit('save-job');
                break;
              case 'next':
                this.$emit('next-step');
                break;
              case 'previous':
                this.$emit('previous-step');
                break;
              default:
                break;
            }
          }
        });
      }
    },
    changeStep(toStep) {
      this.$validator.validateAll().then((result) => {
        if (result) {
          this.$emit('change-step', toStep);
        }
      });
    },
    // nextStep() {
    //   this.$validator.validateAll().then((result) => {
    //     if (result) {
    //       this.$emit('next-step');
    //       this.$validator.reset();
    //     }
    //   });
    // },
    // previousStep() {
    //   this.$validator.validateAll().then((result) => {
    //     if (result) {
    //       this.$emit('previous-step');
    //       this.$validator.reset();
    //     }
    //   });
    // },
    // saveJob() {
    //   this.$validator.validateAll().then((result) => {
    //     if (result) {
    //       this.$emit('save-job');
    //     }
    //   });
    // },
  },
};
</script>
