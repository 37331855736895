<template>
  <div class="pt-4 md:px-3 pb-10">
    <div class="flex items-center text-xl font-semibold">Job Specifics</div>
    <div class="flex flex-col md:flex-row min-w-full py-6">
      <div class="w-full md:pr-3">
        <div class="flex flex-col sm:flex-row pb-3 md:pb-0 gap-x-3">
          <div class="w-full">
            <label class="typo__label">Payment term</label>

            <JobDropdownField
              v-model="jobPost.payTerm"
              :options="filters['payment_term']['payment_term']"
              placeholder=""
              title=""
              error="payment-term"
              class="w-full sm:pr-2"
              :allow-empty="true"
            >
            </JobDropdownField>
          </div>
          <div class="w-full">
            <label class="typo__label">Currency</label>
            <JobDropdownField
              v-model="jobPost.currency"
              :options="filters['currency']['currency']"
              placeholder=""
              title=""
              error="currency"
              class="w-full sm:pr-2"
              :allow-empty="true"
            >
            </JobDropdownField>
          </div>
        </div>
      </div>
      <div class="w-full">
        <div class="flex flex-col sm:flex-row pb-6 md:pb-0 gap-y-3 sm:gap-x-3">
          <div class="w-full">
            <label class="typo__label">Minimum salary</label>
            <vs-input
              id="minSalary"
              v-model="jobPost.minSalary"
              v-validate="'numeric'"
              error="min-salary"
              placeholder="Enter an amount"
              name="Minimum salary"
              class="mt-1 w-full"
            />
            <span v-show="errors.has('Minimum salary')" class="text-danger text-sm">{{ errors.first('Minimum salary') }}</span>
          </div>
          <div class="w-full">
            <label class="typo__label">Maximum salary</label>
            <vs-input
              id="maxSalary"
              v-model="jobPost.maxSalary"
              v-validate="'numeric'"
              error="max-salary"
              placeholder="Enter an amount"
              name="Maximum salary"
              class="mt-1 w-full"
            />
            <span v-show="errors.has('Maximum salary')" class="text-danger text-sm">{{ errors.first('Maximum salary') }}</span>
          </div>
        </div>
      </div>
    </div>

    <div class="flex flex-col md:flex-row pb-6"
      ><JobDropdownField
        v-model="jobPost.accommodation"
        :options="filters['accommodation']['accommodation']"
        error="accommodation"
        :required="LMMCRequired"
        placeholder="Accommodation provided"
        :allow-empty="false"
        title="Staff accommodation"
        size="1"
        class="w-full sm:pr-3 pb-4 md:pb-0"
      >
      </JobDropdownField>
      <JobDropdownField
        v-model="jobPost.meals"
        :options="filters['meals']['meals']"
        error="meals"
        :allow-empty="false"
        :required="LMMCRequired"
        placeholder="Meals provided per day"
        title="Daily staff meals"
        size="1"
        class="w-full"
      >
      </JobDropdownField>
    </div>

    <div class="flex flex-col md:flex-row"
      ><JobDropdownField
        v-if="jobPost.guideType !== 'Adventure' && jobPost.guideType !== 'Culture / Tour'"
        v-model="jobPost.leave"
        :options="filters['leave']['leave']"
        placeholder="Staff leave policy"
        error="staff-leave"
        :allow-empty="false"
        title="Staff leave"
        size="1"
        class="w-full pb-4 md:pb-0 sm:pr-3"
      >
      </JobDropdownField>
      <JobDropdownField
        v-model="jobPost.tips"
        :options="filters['tips']['tips']"
        :allow-empty="false"
        placeholder="Staff tipping policy"
        title="Staff tips"
        error="staff-tips"
        size="1"
        class="w-full"
      >
      </JobDropdownField>
    </div>
    <!-- <div class="w-full">
      <label class="typo__label">Itinerary</label>
      <DropzoneUpload :location="'itinerary_files/test3.jpg'" class="mt-1" @callback="addItinerary"></DropzoneUpload>
    </div> -->
    <portal to="previousStepButton">
      <vs-button v-show="activeStep === 3" class="w-full md:w-auto" color="grey" @click="handleClick('previous')">Previous</vs-button>
    </portal>
    <portal to="saveJob">
      <vs-button v-if="activeStep === 3" class="w-full md:w-auto" @click="handleClick('save')">{{
        !isArchived ? 'Save job' : 'Reactivate job'
      }}</vs-button>
    </portal>
  </div>
</template>

<script>
import JobDropdownField from './JobDropdownField.vue';
// import DropzoneUpload from '../../../components/dropzone/Dropzone.vue';

export default {
  components: {
    JobDropdownField,
    // DropzoneUpload,
  },
  props: {
    jobSync: {
      type: Object,
      required: true,
    },
    activeStep: {
      type: Number,
      required: true,
    },
    isArchived: {
      type: Boolean,
    },
  },
  data() {
    return {
      jobPost: this.jobSync,
      newComp: {
        descr: '',
        selected: '',
        name: '',
        type: '',
        number: '',
        website: '',
        email: '',
        accommodation: '',
        meals: '',
        flights: '',
        leave: '',
        tips: '',
        logo: '',
        banner: '',
      },
    };
  },
  computed: {
    LMMCRequired() {
      if (this.jobPost.guideType === 'Lodge Manager' || this.jobPost.guideType === 'Management Couple') {
        return 'required';
      } else {
        return false;
      }
    },
    filters() {
      return this.$store.getters['filtersModule/getFilters'];
    },
    cities() {
      return this.$store.getters['countriesModule/getCountries'];
    },
    comp() {
      if (this.$store.getters['companiesModule/getComp'] !== undefined) {
        return this.$store.getters['companiesModule/getComp'];
      } else {
        return this.newComp;
      }
    },
  },
  methods: {
    handleClick(inputType) {
      if (inputType === 'previous' && !this.editSync) {
        this.$emit('previous-step');
      } else {
        this.$validator.validateAll().then((result) => {
          if (result) {
            switch (inputType) {
              case 'save':
                this.$emit('save-job');
                break;
              case 'previous':
                this.$emit('previous-step');
                break;
              default:
                break;
            }
          }
        });
      }
    },
    changeStep(toStep) {
      this.$validator.validateAll().then((result) => {
        if (result) {
          this.$emit('change-step', toStep);
        }
      });
    },
    addItinerary(url) {
      this.job.jobPost = url;
    },
    // previousStep() {
    //   this.$validator.validateAll().then((result) => {
    //     if (result) {
    //       this.$emit('previous-step');
    //       this.$validator.reset();
    //     }
    //   });
    // },
    // saveJob() {
    //   this.$validator.validateAll().then((result) => {
    //     if (result) {
    //       this.$emit('save-job');
    //     }
    //   });
    // },
  },
};
</script>

<style scoped>
.vs-button:not(.vs-radius):not(.includeIconOnly):not(.small):not(.large) {
  padding-left: 2.5rem !important;
  padding-right: 2.5rem !important;
}
</style>
