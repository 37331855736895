<!-- eslint-disable vue/no-mutating-props -->
<template>
  <div :class="`vx-col sm:w-1/${size} sm:w-1/${size} w-full mb-2`">
    <div class="flex space-x-1 items-center">
      <label class="typo__label">{{ title }}</label>
      <vs-tooltip v-if="tooltipText" :text="tooltipText" position="top" class="flex items-center">
        <feather-icon icon="HelpCircleIcon" svg-classes="h-4 w-4"></feather-icon>
      </vs-tooltip>
    </div>
    <multiselect
      v-model="getValue"
      v-validate="required"
      class="mt-1 text-clip"
      open-direction="bottom"
      :options="options"
      :close-on-select="closeOnSelect"
      :clear-on-select="false"
      :preserve-search="true"
      :allow-empty="allowEmpty"
      :searchable="searchable"
      :show-labels="false"
      :placeholder="placeholder"
      :disabled="disabled"
      :preselect-first="false"
      :label="label"
      :limit="limit"
      :track-by="label"
      :data-vv-name="error"
      :multiple="multiple"
      :data-vv-value-path="error"
      :class="errors.first(error) ? 'input-error' : ''"
    ></multiselect>
    <span v-show="errors.has(error)" class="text-danger text-sm">{{ errors.first(error) }}</span>
  </div>
</template>

<script>
import Multiselect from 'vue-multiselect';
import 'vue-multiselect/dist/vue-multiselect.min.css';

export default {
  name: 'DropdownFields',
  components: {
    Multiselect,
  },
  props: {
    options: {
      type: Array,
      default: () => ['Hello', 'testing', 'yes'],
    },
    required: {
      type: [String, Boolean],
      default: '',
    },
    multiple: {
      type: Boolean,
      default: false,
    },
    label: {
      type: String,
      default: '',
    },
    closeOnSelect: {
      type: Boolean,
      default: true,
    },
    title: {
      type: String,
      default: '',
    },
    error: {
      type: String,
      default: '',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    placeholder: {
      type: String,
      default: 'Select one',
    },
    tooltipText: {
      type: String,
      default: '',
    },
    trackBy: {
      type: String,
      default: '',
    },
    value: {
      default: '',
      type: [String, Array, Object],
    },
    size: {
      type: String,
      default: '',
    },
    allowEmpty: {
      type: Boolean,
      default: true,
    },
    searchable: {
      type: Boolean,
      default: false,
    },
    limit: {
      type: Number,
      default: 999,
    },
  },
  computed: {
    getValue: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit('input', value);
      },
    },
  },
  created() {
    this.$validator = this.$parent.$validator;
  },
  // watch: {
  //   value() {
  //     this.$emit('input', this.value);
  //   },
  // },
};
</script>
<style>
.multiselect__tags-wrap {
  @apply flex flex-wrap !important;
}
.multiselect__tags {
  @apply flex flex-col xl:flex-row !important;
}
</style>
